import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export const VacancyContext = React.createContext({
  title: '',
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title = '', flexContent, seo },
  },
  location = {},
}) => (
  <Layout location={location}>
    <SEO seo={seo} />

    <VacancyContext.Provider
      value={{
        title,
      }}
    >
      <FlexContentHandler
        prefix="VacancyPost_Flexcontent"
        fields={flexContent}
        location={location}
        title={title}
      />
    </VacancyContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query VacancyPostById($id: String!) {
    page: wpVacancyPost(id: { eq: $id }) {
      ...generalVacancyPostFragment
    }
  }
`

export default PageTemplate
